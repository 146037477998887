<!--电梯模块--栋内互访-->
<template>
  <div id="exchangeVisits">
   <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%;border-right:1px solid rgba(12, 12, 12, 0.1); ">
        <my-navder :list="list" @select="getMsgFormSon"></my-navder>
      </a-spin>
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-secondTitle>{{this.$t('elevatorexchangevisits.a10')}}</my-secondTitle>
          <div style="margin-top:20px;" v-if="false">
            <template v-for="tag in srcfloortags">
              <a-checkable-tag
                :key="tag.key"
                :checked="selectedsrcFloorTags.indexOf(tag) > -1"
                @change="checked => handleChangesrcFloor(tag, checked)"
                style="margin-bottom:8px;cursor:pointer;"
                class="floorTag"
              >
                {{ tag.title }}
              </a-checkable-tag>
            </template>
          </div>
          <div style="margin-top:20px;margin-bottom:20px;">
                <template v-for="tag in srcroomtags">
                <a-checkable-tag
                    :key="tag.key"
                    :checked="selectedsrcRoomTags.indexOf(tag) > -1"
                    @change="checked => handleChangesrcRoom(tag, checked)"
                    style="cursor:pointer;"
                    class="roomTag"
                >
                    {{ tag.title }}
                </a-checkable-tag>
                </template>
            </div>
          <my-secondTitle v-if="selectedsrcRoomTags.length>0">{{this.$t('elevatorexchangevisits.a11')}}</my-secondTitle>
          <div style="margin-top:20px;margin-bottom:10px;"  v-if="selectedsrcRoomTags.length>0">
            <template v-for="tag in desfloortags">
              <a-checkable-tag
                :key="tag.key"
                :checked="selecteddesFloorTags.indexOf(tag) > -1"
                @change="checked => handleChangedesFloor(tag, checked)"
                style="margin-bottom:8px;cursor:pointer;"
                class="floorTag"
              >
                {{ tag.title }}
              </a-checkable-tag>
            </template>
          </div>
          <my-secondTitle v-if="selecteddesFloorTags.length>0">{{this.$t('elevatorexchangevisits.a11')}}</my-secondTitle>
           <div style="margin-top:20px;margin-bottom:20px;">
              <template v-for="tag in desroomtags">
                <a-checkable-tag
                    :key="tag.key"
                    :checked="selecteddesRoomTags.indexOf(tag) > -1"
                    @change="checked => handleChangedesRoom(tag, checked)"
                    style="cursor:pointer;"
                    class="roomTag"
                >
                    {{ tag.title }}
                </a-checkable-tag>
              </template>
            </div>
          </div>
      </a-spin>
       <div class="main-show" v-else>
         <div class="exchangeVisitsSteps" style="width:80%;min-width:580px;padding:40px 40px 20px;">
            <div style="margin-bottom: 20px;font-size: 20px;text-align:left;">{{this.$t('elevatorexchangevisits.a1')}}</div>
            <a-steps>
              <a-step>
                    <template>
                      <span slot="icon" >
                          <a-icon type="user" style="width:40px;height:40px;font-size:14px;border: 1px solid rgba(0, 0, 0, 0.25);line-height:40px;border-radius:50%;color: rgba(12, 12, 12, 0.45);"/>
                          <div style="font-size:13px;color: rgba(12, 12, 12, 0.45);">{{this.$t('elevatorexchangevisits.a2')}}</div>
                          <div style="font-size:10px;color: rgba(12, 12, 12, 0.45);line-height:12px;">{{this.$t('elevatorexchangevisits.a5')}}</div>
                      </span>               
                  </template>
              </a-step>
              <a-step>
              <template>
                  <span slot="icon" >
                      <a-icon type="solution" style="width:40px;height:40px;font-size:14px;border: 1px solid rgba(0, 0, 0, 0.25);line-height:40px;border-radius:50%;"/>
                      <div style="font-size:13px;color: rgba(12, 12, 12, 0.45);">{{this.$t('elevatorexchangevisits.a3')}}</div>
                      <div style="font-size:10px;color: rgba(12, 12, 12, 0.45);line-height:12px;">{{this.$t('elevatorexchangevisits.a5')}}</div>
                  </span>               
              </template>
            </a-step>
            <a-step>
              <template>
                  <span slot="icon" >
                      <a-icon type="check" style="width:40px;height:40px;font-size:14px;border: 1px solid rgba(0, 0, 0, 0.25);line-height:40px;border-radius:50%;"/>
                      <div style="font-size:13px;color: rgba(12, 12, 12, 0.45);">{{this.$t('elevatorexchangevisits.a4')}}</div>
                      <div style="font-size:10px;color: rgba(12, 12, 12, 0.45);line-height:12px;">{{this.$t('elevatorexchangevisits.a6')}}</div>
                  </span>               
              </template>
            </a-step>
            </a-steps>
         </div>
         <div class="exchangeVisitsSteps" style="width:50%;min-width:460px;padding:20px 40px;">
            <div style="margin-bottom: 20px;font-size: 20px;text-align:left;">{{this.$t('elevatorexchangevisits.a7')}}</div>
            <a-steps>
              <a-step>
                    <template>
                      <span slot="icon" >
                          <a-icon type="user" style="width:40px;height:40px;font-size:14px;border: 1px solid rgba(0, 0, 0, 0.25);line-height:40px;border-radius:50%;color: rgba(12, 12, 12, 0.45);"/>
                          <div style="font-size:13px;color: rgba(12, 12, 12, 0.45);">{{this.$t('elevatorexchangevisits.a2')}}</div>
                          <div style="font-size:10px;color: rgba(12, 12, 12, 0.45);line-height:12px;">{{this.$t('elevatorexchangevisits.a5')}}</div>
                      </span>               
                  </template>
              </a-step>
              <a-step>
              <template>
                  <span slot="icon" >
                      <a-icon type="check" style="width:40px;height:40px;font-size:14px;border: 1px solid rgba(0, 0, 0, 0.25);line-height:40px;border-radius:50%;"/>
                      <div style="font-size:13px;color: rgba(12, 12, 12, 0.45);">{{this.$t('elevatorexchangevisits.a4')}}</div>
                      <div style="font-size:10px;color: rgba(12, 12, 12, 0.45);line-height:12px;">{{this.$t('elevatorexchangevisits.a8')}}</div>
                  </span>               
              </template>
            </a-step>
            </a-steps>
         </div>
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleIndoorCallLadder" :disabled="selectedsrcRoomTags.length===0||selecteddesRoomTags.length!==0" style="width:160px;">{{this.$t('elevatorexchangevisits.a7')}}</a-button>
        <a-button type="primary" @click="handleExchangeVisits" :disabled="selectedsrcRoomTags.length===0||selecteddesRoomTags.length===0" style="width:160px;">{{this.$t('elevatorexchangevisits.a9')}}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import secondTitle from "../Title/secondTitle";
import Navdar from "../../../components/Navdar/vertical";
import { isEmptrValue,getApiFloorList,getApiRoomList,cmdControlRemoteFunction } from "../../../api/elevator";
export default {
  data() {
    return {
      name: "",
      spinning: false,
      list: [
          {
              title: this.$t('elevatorexchangevisits.a13'),
              key: '1-1',
              children:[],
          },
      ],
      srcfloortags:[],
      desfloortags:[],
      selectedsrcFloorTags:[],
      selecteddesFloorTags:[],
      srcroomtags: [],
      desroomtags:[],
      selectedsrcRoomTags:[],
      selecteddesRoomTags:[],
      alter: false,
      initial: true,
      id: undefined,
      loading: false,
    };
  },
  props: {    
    siteid: {
      type: Object,
    },
  },
  watch: {
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();
  },
  methods: {
   navderList(){
     const listtemp = [{ title: this.$t('elevatorexchangevisits.a13'),key: '1-1',children:[]}]
     const params = {'fr_ID':this.siteid.build}
     getApiFloorList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = {key:item.id+'_'+item.fr_show,title:item.fr_show}
              listtemp[0].children.push(model)
              }
          )
          this.list =  listtemp
          this.srcfloortags = this.list[0].children  
          this.desfloortags = this.list[0].children   
        }else{
          this.list =  listtemp 
        }
      }
     )
   },
   getMsgFormSon(data){
     this.id = parseInt(data.split('_')[0])
     this.selecteddesFloorTags = []
     this.srcroomtags = []
     this.desroomtags = []
     this.selectedsrcRoomTags = []
     this.selecteddesRoomTags = []
     this.getSrcRoomLists(data.split('_')[1])     
   },
   handleExchangeVisits(){
     this.loading = true;
     const params = { "ele_ID": 0,"funcType": 4,"srcRoomId": this.selectedsrcRoomTags[0].key,"desRoomId": this.selecteddesRoomTags[0].key}
     cmdControlRemoteFunction(params).then((res)=>{
       if(res.code===200){
         this.loading = false;
              this.$message.success(
                this.$t('elevatorexchangevisits.a14')
              );
              setTimeout(() => {
                
              }, 5000);
       }else{
         this.loading = false;
         this.$message.error(this.$t('elevatorexchangevisits.a15'));
           setTimeout(() => {
              
           }, 3000);
       }
     })
   },
   handleIndoorCallLadder(){
     this.loading = true;
     const params = { "ele_ID": 0,"funcType": 2,"srcRoomId": this.selectedsrcRoomTags[0].key,"desRoomId": 0}
     cmdControlRemoteFunction(params).then((res)=>{
       if(res.code===200){
         this.loading = false;
              this.$message.success(
                this.$t('elevatorexchangevisits.a14')
              );
              setTimeout(() => {
                
              }, 5000);
       }else{
         this.loading = false;
         this.$message.error(this.$t('elevatorexchangevisits.a15'));
           setTimeout(() => {
              
           }, 3000);
       }
     })
   },
   handleChangesrcFloor(tag,checked){
     const { selectedsrcFloorTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selectedsrcFloorTags.filter(t => t !== tag);
      this.selectedsrcFloorTags = nextSelectedTags;
      if(nextSelectedTags.length>0){
        this.getSrcRoomLists(nextSelectedTags[0].title)
      }
   },
   handleChangedesFloor(tag,checked){
     this.selecteddesRoomTags = []
     this.desroomtags = []
     const { selecteddesFloorTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selecteddesFloorTags.filter(t => t !== tag);
      this.selecteddesFloorTags = nextSelectedTags;
      if(nextSelectedTags.length>0){
        this.getDesRoomLists(nextSelectedTags[0].title)
      }
   },
   handleChangesrcRoom(tag,checked){
     const { selectedsrcRoomTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selectedsrcRoomTags.filter(t => t !== tag);
      this.selectedsrcRoomTags = nextSelectedTags;
   },
   handleChangedesRoom(tag,checked){
     const { selecteddesRoomTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selecteddesRoomTags.filter(t => t !== tag);
      this.selecteddesRoomTags = nextSelectedTags;
   },
   getSrcRoomLists(disPlayfloor){
     const params =  {'rm_bd_ID':this.siteid.build,'rm_floor':disPlayfloor}
     const listtemp = []
     getApiRoomList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = {key:item.rm_ID,title:item.rm_ShowName}
              listtemp.push(model)
              }
          )
          this.srcroomtags = listtemp 
        }else{
          this.srcroomtags = listtemp 
        }
      }
    )
   },
   getDesRoomLists(disPlayfloor){
     const params =  {'rm_bd_ID':this.siteid.build,'rm_floor':disPlayfloor}
     const listtemp = []
     getApiRoomList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = {key:item.rm_ID,title:item.rm_ShowName}
              listtemp.push(model)
              }
          )
          this.desroomtags = listtemp 
        }else{
          this.desroomtags = listtemp 
        }
      }
    )
   }
  },
  components: {
    "my-secondTitle": secondTitle,
    "my-navder": Navdar
  },
};
</script>
<style scoped>
#exchangeVisits {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);/* calc(100% - 250px);*/
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  width: 120px;
  margin: 0px 10px;
}
.modal1 {
  padding-bottom: 15px;
}
.floorTag{
  cursor:pointer;
  width:32px;
  height:32px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:50%;
}
.floorTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
.roomTag{
  cursor:pointer;
  min-width:42px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:5%;
}
.roomTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
</style>