<!--电梯模块--时间段-->
<template>
  <div id="controlElevator">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%;border-right:1px solid rgba(12, 12, 12, 0.1); ">
        <my-navder :list="list" @select="getMsgFormSon"></my-navder>
      </a-spin>
    </div>
    <div class="right" ref="right">
      <a-spin :spinning="rightSpinning" class="main-spinning" v-if="id">
        <div class="main">
          <my-secondTitle>{{$t('controlTimetable.a2')}}</my-secondTitle>
          <div style="margin-top:16px;">
              <my-tabletitle>{{$t('controlTimetable.a3')}}</my-tabletitle>
              <a-table
                :columns="columns"
                :dataSource="dataSource"
                :scroll="scroll"
                :pagination="pagination"
                :loading="loading"
              >
                <template slot="commonFloor" slot-scope="text, record">
                  <a-tag color="#F8B62D" v-if="record.commonFloor">
                    {{$t('controlTimetable.a18')}}
                  </a-tag>
                  <a-tag color="#0FCE0F" v-else>
                    {{$t('controlTimetable.a19')}}
                  </a-tag>
                </template>
                <template slot="elevatordoorType" slot-scope="text">
                  <div style="display:flex;" v-if="text === 0">
                    <div class="elevatordoorTypeIcon active">{{ $t('controlTimetable.a20') }}</div>
                    <div class="elevatordoorTypeIcon active">{{ $t('controlTimetable.a21') }}</div>
                  </div>
                  <div style="display:flex;" v-if="text === 1">
                    <div class="elevatordoorTypeIcon active">{{ $t('controlTimetable.a20') }}</div>
                    <div class="elevatordoorTypeIcon">{{ $t('controlTimetable.a21') }}</div>
                  </div>
                  <div style="display:flex;" v-if="text === 2">
                    <div class="elevatordoorTypeIcon">{{ $t('controlTimetable.a20') }}</div>
                    <div class="elevatordoorTypeIcon active">{{ $t('controlTimetable.a21') }}</div>
                  </div>
                </template>
                <template slot="elevatorTimeType" slot-scope="text">
                  {{ text | elevatorTimeTypeFilter}}
                </template>
              </a-table>
          </div>
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="calendar" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{this.$t('controlTimetable.a6')}}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button type="primary" style="width:80px;" @click="handleEdit">{{ $t('controlTimetable.a4') }}</a-button>
        <a-button type="primary"  @click="handleApplication">{{ $t('controlTimetable.a5') }}</a-button>
      </div>
    </div>
     <!-- 时间表楼层设置 -->
    <a-modal
      :title="$t('controlTimetable.a7')"
      :width="320"
      centered
      :visible="visiblefloor"
      :destroyOnClose="true"
      @cancel="handleCancel"
      v-drag
    >
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          @click="handleOk"
        >
          {{ $t('controlTimetable.a8') }}
        </a-button>
        <a-button key="back" @click="handleCancel">
          {{ $t('controlTimetable.a9') }}
        </a-button>
      </template>
      <div
        v-loading="loadingmodal"
        :element-loading-text="$t('controlTimetable.a1')"
      >
        <div style="display:flex;margin-bottom: 16px;">
          <div>{{ $t('controlTimetable.tb2') }}:</div>
          <a-select
            v-model="form.id"
            :placeholder="$t('controlTimetable.a10')"
            style="flex-grow:1; margin: 0px 20px;"
            @change="changefloor"
          >
            <a-select-option v-for="item in dataSource" :key="item.id">
              {{ item.floorDisplay }}
            </a-select-option>
          </a-select>
        </div>
        <div style="display:flex;margin-bottom: 16px;">
          <div>{{ $t('controlTimetable.a12') }}:</div>
          <a-select
            v-model="form.main"
            :placeholder="$t('controlTimetable.a14')"
            style="flex-grow:1;margin: 0px 20px;"
            :disabled="form.commonFloor "
          >
            <a-select-option v-for="item in listTime" :key="item.key">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>
        <div style="display:flex;margin-bottom: 16px;">
          <div>{{ $t('controlTimetable.a13') }}:</div>
          <a-select
            v-model="form.sub"
            :placeholder="$t('controlTimetable.a14')"
            style="flex-grow:1; margin: 0px 20px;"
            :disabled="form.commonFloor "
          >
            <a-select-option v-for="item in listTime" :key="item.key">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>
        <div style="display:flex;margin-bottom: 16px;">
          <div>{{ $t('controlTimetable.a15') }}:</div>
          <a-radio-group v-model="form.mode" style="flex-grow:1; margin: 0px 20px;">            
            <a-radio :value="0">{{ $t('controlTimetable.a16') }}</a-radio>
            <a-radio :value="1" :disabled="form.commonFloor">{{ $t('controlTimetable.a17') }}</a-radio>
          </a-radio-group>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import secondTitle from "../Title/secondTitle";
import Navdar from "../Navdar/vertical";
import {
  tableSort
} from "@/utils/utils";
import { 
  isEmptrValue,
  getTimeIntervalList,
  getApiElevatorList,
  getApiFloorListByMcId,
  getApiRoomList,
  cmdControlSetITMFunction,
  cmdControlElevator,
  updateTimeIntervalFloor,
  applicationToDevice 
} from "../../../api/elevator";
// 电梯门类型
let elevatordoorType = {
  2: {
    text: '副门'
  },
  1: {
    text: '主门'
  },
  0: {
    text: '主副门'
  }
}
let that;
export default {
  data() {
    return {
      elevatorTimeType:{},
      spinning: false,
      rightSpinning: false,
      id: undefined, // 控制器id
      elevatorid: undefined, // 电梯id
      list: [ ], // 电梯列表
      listTime: [ { key: 0, title: '--'} ], // 时间段列表
      listTimeType: [ { key: 0, title: '--'} ], // 时间段列表
      floortags:[],
      selectedFloorTags:[],
      roomtags: [],
      selectedRoomTags:[],
      loading: false, //表格数据加载
      params: {
        "readerId": 0,
        "eleProductNum_id": 0,
        "funcType": 0,
        "srcRoomId": 0,
        "desRoomId": 0
      },
      visiblefloor:false, // 楼层时间表弹框
      loadingmodal: false, // 弹窗的加载
      form:{
        id:0,
        doorType:0,
        main: 0,
        sub: 0,
        mode: 0,
        commonFloor: false,
      },
      // 提交给后端的楼层模板，后面转为list发给后端设置楼层时间段
      floormodel:{
        eleProductNumName: "楼栋2电梯2", // 电梯名称
        eleProductNum_Id: 38, // 电梯ID号
        fr_num: 1, // 楼层物理楼层
        fr_show: "-2", // 楼层数显楼层
        id: 718, // 楼层ID
        isOpenFloor: false, // 是否为公共层：true-公共层 ，false-权限层
        mainSubDoorType: 1, // 电梯门类型：0-主副门，1-主门 ，2-主门 
        mcEleFlrCode: 42,
        mcName: "电梯控制器22", // 控制器名称
        mc_id: 42,         // 控制器ID
        mtiId_mainFlr: 0, //主门楼层时间段
        mtiId_subFlr: 0,  //副门楼层时间段
      },
      dataSource: [], // 楼层时间段数据
      columns:[
        {
          title: this.$t('controlTimetable.tb1'),
          dataIndex: 'floorPyh',
          width: 80,
          sorter: (a, b) => {
            return tableSort(a, b, "floorPyh");
          },
          scopedSlots: { customRender: 'floorPyh' }
        },
        {
          title: this.$t('controlTimetable.tb2'),
          dataIndex: 'floorDisplay',
          width: 80,
          sorter: (a, b) => {
            return tableSort(a, b, "floorDisplay");
          },
          scopedSlots: { customRender: 'floorDisplay' }
        },
        {
          title: this.$t('controlTimetable.tb3'),
          dataIndex: 'commonFloor',
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "commonFloor");
          },
          scopedSlots: { customRender: 'commonFloor' }
        },
        {
          title: this.$t('controlTimetable.tb4'),
          dataIndex: 'elevatordoorType',
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "elevatordoorType");
          },
          rowClassName:'elevatordoorType',
          scopedSlots: { customRender: 'elevatordoorType' }
        },
        {
          title: this.$t('controlTimetable.tb5'),
          dataIndex: 'elevatorMainDoorFloorTime',
          width: 180,
          sorter: (a, b) => {
            return tableSort(a, b, "elevatorMainDoorFloorTime");
          },
          scopedSlots: { customRender: 'elevatorTimeType' }
        },
        {
          title: this.$t('controlTimetable.tb6'),
          dataIndex: 'elevatorSubDoorFloorTime',
          width: 180,
          sorter: (a, b) => {
            return tableSort(a, b, "elevatorSubDoorFloorTime");
          },
          scopedSlots: { customRender: 'elevatorTimeType' }
        }
      ], // 楼层时间段表头
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => this.$t('elevatorQrCodeRecords.a12') + ` ${total} `+ this.$t('elevatorQrCodeRecords.a13'), //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        onShowSizeChange: (current, pageSize) => {
          this.$emit("refresh");
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.$emit("refresh");
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
        // current:1,
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
    };
  },
  props: {    
    siteid: {
      type: Object,
    },
    scroll:{
      type: Object,
    }
  },
  watch: {
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();
    // 进入页面时调用获取时间段列表方法
    this.getTimeList();
  },
  beforeCreate(){
    that = this;
  },
  methods: {
    // 获取时间表列表
    getTimeList(){
      const ini = { key: 0, title: "--" }
      const listtemp = [];
      listtemp.push(ini)
      const params = {'mti_areaId': this.siteid.area,'mti_name':"null"};
      getTimeIntervalList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = { key: item.mti_ID, title: item.mti_name }
              listtemp.push(model)
            }
          )
            this.listTime =  listtemp
          } else {
            this.listTime =  listtemp 
        }
        this.elevatorTimeType = this.listTime.reduce((acc,v) => { acc[v.key] = { text: v.title};return acc;},{})
      })
      .catch((err) => {
        this.listTime = [{key:0,title:'--'}];
        this.elevatorTimeType = Object.entries(this.listTime)
        console.log(err)
      });
    },
    navderList(){
      const listtemp = [{ title: this.$t('controlElevator.a1'),key: '1-1',children:[]}];
      const params = {'clientId':this.siteid.client,'areaId':this.siteid.area,'buildId':this.siteid.build};
      getApiElevatorList(params).then((res)=>{
          if(res.code===200){
            res.data.forEach(
              item =>{
                const model = {key:item.id+'-'+item.mcId,title:item.eleName}
                listtemp[0].children.push(model)
                }
            )
            this.list =  listtemp
          }else{
            this.list =  listtemp 
          }
        }
      );
    },
    getMsgFormSon(data){
      this.elevatorid =  parseInt(data.split('-')[0]);
      this.id = parseInt(data.split('-')[1]);
      this.loading = true;
      getApiFloorListByMcId({'mc_id':this.id}).then((res)=>{
          const list = []
          if(res.code===200){
            res.data.forEach(
              item =>{
                const _data = {
                  id: item.id, // 楼层ID
                  elevatorName: item.eleProductNumName, //item.eleProductNumName, // 电梯名称
                  elevatorid: item.eleProductNum_Id, //, // 电梯ID号
                  floorPyh: item.fr_num, // 楼层物理楼层
                  floorDisplay: item.fr_show, // 楼层数显楼层
                  commonFloor: item.isOpenFloor, // 是否为公共层：true-公共层 ，false-权限层
                  elevatordoorType: item.mainSubDoorType, // 电梯门类型：0-主副门，1-主门 ，2-主门 
                  elevatorMainDoorFloorTime: item.mtiId_mainFlr, //主门楼层时间段
                  elevatorMainDoorFloorTimeName: '- -',  //主门楼层时间段名称
                  elevatorSubDoorFloorTime: item.mtiId_subFlr,  //副门楼层时间段
                  elevatorSubDoorFloorTimeName: '- -',  //副门楼层时间段名称
                  floorCode: item.mcEleFlrCode, // 电梯数显代号
                  deviceName: item.mcName, //item.mcName, // 控制器名称
                  deviceid: item.mc_id, // 控制器ID
                };
                list.push(_data);
              }
            )
            this.dataSource = list;
          } else{
          }
          this.loading = false;
        }
      )
    },
    // 关闭楼层时间表弹窗
    handleCancel() {
      this.visiblefloor = false;
    },
    // 提交楼层时间表设置参数
    handleOk(){
      if(this.form.commonFloor && this.form.mode === 1){
        setTimeout(() => {
          this.$error({
            title: "电梯公共层不能应用全部楼层",
            centered: true,
            });
        }, 5);
        return false
      }
      const data = this.dataSource
      data.forEach(
        item =>{
          if(this.form.mode === 0){
            if(item.id === this.form.id){
              // item.elevatordoorType = this.form.doorType;
              item.elevatorMainDoorFloorTime = item.commonFloor?0:this.form.main;
              item.elevatorSubDoorFloorTime = item.commonFloor?0:this.form.sub;
            }
          }else{
            // item.elevatordoorType = this.form.doorType;
            item.elevatorMainDoorFloorTime = item.commonFloor?0:this.form.main;
            item.elevatorSubDoorFloorTime = item.commonFloor?0:this.form.sub;
          }
      })
      this.dataSource = data
      const fromList = []
      this.dataSource.forEach(
        item =>{
          const model = {
            id: item.id,
            eleProductNumName: item.elevatorName,
            eleProductNum_Id: item.elevatorid,
            fr_num: item.floorPyh,
            fr_show: item.floorDisplay,            
            isOpenFloor: item.commonFloor,
            mainSubDoorType: item.elevatordoorType,
            mcEleFlrCode: item.floorCode,
            mcName: item.deviceName,
            mc_id: item.deviceid,
            mtiId_mainFlr: item.elevatorMainDoorFloorTime,
            mtiId_subFlr: item.elevatorSubDoorFloorTime,
          }
          fromList.push(model)
        }
      )
      this.UpdateTimeIntervalFloor(fromList)
    },
    // api 提交后台更新列表数据
    UpdateTimeIntervalFloor(data){
      updateTimeIntervalFloor(data).then((res) => {
        if (res.code === 200) {
          this.visiblefloor = false;
          setTimeout(() => {
            this.$success({
              title: this.$t('controlTimetable.a22'),
              centered: true,
              });
          }, 5);
        } else {
          this.$message.info( this.$t('controlTimetable.a23') + `${res.msg}`)
        }
      })
    },
    // 应用
    handleApplication(){
      const that = this
      this.$confirm({
         title: this.$t('controlTimetable.a24'),
         content: this.$t('controlTimetable.a25'),
         okText: this.$t('controlTimetable.a26'),
         cancelText: this.$t('controlTimetable.a27'),
         centered: 'true',
         onOk () {
            that.rightSpinning = true
            applicationToDevice(that.id).then((res) => {
                if (res.code === 200) {
                    that.$message.info(this.$t('controlTimetable.a28'))
                } else {
                that.$message.info( this.$t('controlTimetable.a28') + `${res.msg}`)
                }
                that.rightSpinning = false
            })
         },
         onCancel () {
         }
      })
    },
    handleEdit() {
      if(!isEmptrValue(this.dataSource)){
        this.form.id = this.dataSource[0].id;
        this.form.floor = this.dataSource[0].floorDisplay;
        this.form.doorType = this.dataSource[0].elevatordoorType;
        this.form.main = this.dataSource[0].elevatorMainDoorFloorTime;
        this.form.sub = this.dataSource[0].elevatorSubDoorFloorTime;
        this.form.mode = 0;
        this.form.commonFloor = this.dataSource[0].commonFloor;
      }
      this.visiblefloor = true;
    },
    changefloor(index){
      this.dataSource.forEach(
          item =>{
            if(item.id === index){
              this.form.doorType = item.elevatordoorType;
              this.form.main = item.elevatorMainDoorFloorTime;
              this.form.sub = item.elevatorSubDoorFloorTime;
              this.form.mode = 0;
              this.form.commonFloor = item.commonFloor;
            }
        })
    }
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-secondTitle": secondTitle,
    "my-navder": Navdar,
  },
  filters: {
    elevatordoorTypeFilter (type) {
      return elevatordoorType[type].text
    },
    elevatorTimeTypeFilter (type) {
      return that.elevatorTimeType[type].text
    }
  }
};
</script>
<style scoped>
#controlElevator {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
  border-right:1px solid rgba(12, 12, 12, 0.1);
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 0px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  width: 120px;
  margin: 0px 10px;
}
.modal1 {
  padding-bottom: 15px;
}
.floorTag{
  cursor:pointer;
  width:32px;
  height:32px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:50%;
}
.floorTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
.roomTag{
  cursor:pointer;
  min-width:42px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:5%;
}
.roomTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
.elevatordoorTypeIcon{
  height:32px;
  width:32px;
  border-radius: 50%;
  line-height:32px;
  background-color: #f0f5f7;
  color: #7682CE;
  text-align: center;
  margin:0px 5px 0px 0px;
}
.active{
  background-color: #7682CE;
  color: #f0f5f7;
}
</style>