<!--电梯模块--远程控制-->
<template>
  <div id="controlElevator">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%;border-right:1px solid rgba(12, 12, 12, 0.1); ">
        <my-navder :list="list" @select="getMsgFormSon"></my-navder>
      </a-spin>
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-secondTitle>{{this.$t('controlElevator.a3')}}</my-secondTitle>
         <div style="margin-bottom:10px;margin-top:10px;">
            <a-button type="primary" @click="handleControlItm(2)">{{this.$t('controlElevator.a4')}}</a-button>
            <a-button type="primary" @click="handleControlItm(1)">{{this.$t('controlElevator.a5')}}</a-button>
            <div style="color:rgba(12, 12, 12, 0.3);padding-left:10px;padding:10px 0px 0px 10px;">
               {{this.$t('controlElevator.a6')}}
            </div>
         </div>
          <my-secondTitle>{{this.$t('controlElevator.a7')}}</my-secondTitle>
          <div style="margin-top:16px;">
            <template v-for="tag in floortags">
              <a-checkable-tag
                :key="tag.key"
                :checked="selectedFloorTags.indexOf(tag) > -1"
                @change="checked => handleChangeFloor(tag, checked)"
                style="margin-bottom:8px;cursor:pointer;"
                class="floorTag"
              >
                {{ tag.title }}
              </a-checkable-tag>
            </template>
          </div>
          <my-secondTitle v-show="selectedFloorTags.length > 0">{{this.$t('controlElevator.a8')}}</my-secondTitle>
          <div style="margin-top:16px;"  v-show="selectedFloorTags.length > 0">
            <template v-for="tag in roomtags">
              <a-checkable-tag
                :key="tag.key"
                :checked="selectedRoomTags.indexOf(tag) > -1"
                @change="checked => handleChangeRoom(tag, checked)"
                style="cursor:pointer;"
                class="roomTag"
              >
                {{ tag.title }}
              </a-checkable-tag>
            </template>
            <span style="line-height:32px;font-size:14px;color:#808080;" v-if="roomtags.length === 0">{{this.$t('controlElevator.a9')}}</span>
          </div>
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="calendar" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{this.$t('controlElevator.a2')}}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button type="primary" style="width:160px;" @click="handleControlElevator" :disabled="selectedRoomTags.length === 0">{{this.$t('controlElevator.a10')}}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import secondTitle from "../Title/secondTitle";
import Navdar from "../Navdar/vertical";
import { isEmptrValue,getApiElevatorList,getApiFloorListByMcId,getApiRoomList,cmdControlSetITMFunction,cmdControlElevator } from "../../../api/elevator";
export default {
  data() {
    return {
      name: "",
      spinning: false,
      id:undefined,
      elevatorid:undefined,
      list: [],
      floortags:[],
      floortags1: [
        {key:99,title:'-2',model:{name:''}},
        {key:0,title:'-1',model:{}},
        {key:1,title:'1',model:{}},
        {key:2,title:'2',model:{}},
        {key:3,title:'3',model:{}},
        {key:4,title:'4',model:{}},
        {key:5,title:'5',model:{}},
        {key:6,title:'6',model:{}},
        {key:7,title:'7',model:{}},
        {key:8,title:'8',model:{}},
        {key:9,title:'9',model:{}},
        {key:10,title:'10',model:{}},
        {key:11,title:'11',model:{}},
        {key:12,title:'12',model:{}},
        {key:13,title:'13',model:{}},
        {key:14,title:'14',model:{}},
        {key:15,title:'15',model:{}},
        {key:16,title:'16',model:{}},
        {key:17,title:'17',model:{}},
        {key:18,title:'18',model:{}},
        {key:19,title:'19',model:{}},
        {key:20,title:'20',model:{}},
        {key:21,title:'21',model:{}},
        {key:22,title:'22',model:{}},
        {key:23,title:'23',model:{}},
        {key:24,title:'24',model:{}},
        {key:25,title:'10',model:{}},
        {key:26,title:'11',model:{}},
        {key:27,title:'12',model:{}},
        {key:28,title:'13',model:{}},
        {key:29,title:'14',model:{}},
        {key:30,title:'15',model:{}},
        {key:31,title:'16',model:{}},
        {key:32,title:'17',model:{}},
        {key:33,title:'18',model:{}},
        {key:34,title:'19',model:{}},
        {key:35,title:'20',model:{}},
        {key:36,title:'21',model:{}},
        {key:37,title:'22',model:{}},
        {key:38,title:'23',model:{}},
        {key:39,title:'24',model:{}},
        
      ],
      selectedFloorTags:[],
      roomtags: [],
      selectedRoomTags:[],
      loading: false,
      params: {
        "readerId": 0,
        "eleProductNum_id": 0,
        "funcType": 0,
        "srcRoomId": 0,
        "desRoomId": 0
      },
    };
  },
  props: {    
    siteid: {
      type: Object,
    },
  },
  watch: {
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();
  },
  methods: {
   navderList(){
     const listtemp = [{ title: this.$t('controlElevator.a1'),key: '1-1',children:[]}]
     const params = {'clientId':this.siteid.client,'areaId':this.siteid.area,'buildId':this.siteid.build}
     getApiElevatorList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = {key:item.id+'-'+item.mcId,title:item.eleName}
              listtemp[0].children.push(model)
              }
          )
          this.list =  listtemp
        }else{
          this.list =  listtemp 
        }
      }
     )
   },
   getMsgFormSon(data){
     this.elevatorid =  parseInt(data.split('-')[0])
     this.id = parseInt(data.split('-')[1])
     this.floortags = []
     this.selectedFloorTags = []
     this.roomtags = []
     this.selectedRoomTags = []
     getApiFloorListByMcId({'mc_id':this.id}).then((res)=>{
          if(res.code===200){
            res.data.forEach(
              item =>{
                const model = {key:item.id,title:item.fr_show,data:item}
                this.floortags.push(model)
                }
            )
          }else{
          }
        }
      )
   },
   getRoomLists(buildid,disPlayfloor){
     const params =  {'rm_bd_ID':buildid,'rm_floor':disPlayfloor}
     const listtemp = []
     getApiRoomList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = {key:item.rm_ID,title:item.rm_ShowName}
              listtemp.push(model)
              }
          )
          this.roomtags = listtemp 
        }else{
          this.roomtags = listtemp 
        }
      }
    )
   },
   handleChangeFloor(tag,checked){
     const { selectedFloorTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selectedFloorTags.filter(t => t !== tag);
      this.selectedFloorTags = nextSelectedTags;
      if(nextSelectedTags.length>0){
        this.getRoomLists(this.siteid.build,nextSelectedTags[0].title)
      }
   },
   handleChangeRoom(tag,checked){
     const { selectedRoomTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selectedRoomTags.filter(t => t !== tag);
      this.selectedRoomTags = nextSelectedTags;
   },
   handleControlItm(val){
     this.loading = true;
     cmdControlSetITMFunction(this.elevatorid,val).then((res) => {
      if (res.code == 200) {
          this.loading = false;
          this.$message.success(
            this.$t('controlElevator.a11')
          );
        } 
        else {
          this.loading = false;
          this.$message.error(
            this.$t('controlElevator.a12')+`: ${res.msg}`
          );
        }
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
   },
   handleControlElevator(){
     const params = {
        "eleProductNum_id": this.elevatorid,
        "readerId": 0,
        "funcType": 32,
        "srcRoomId": 0,
        "desRoomId": this.selectedRoomTags[0].key
     }
     cmdControlElevator(params).then((res)=>{
       if(res.code===200){
         this.$message.success("召梯请求操作成功,电梯楼层已解除受限");
         setTimeout(() => {}, 3000);
       }else{
         this.$message.error("召梯请求操作失败");
         setTimeout(() => {   }, 3000);
       }
       this.loading = false;
     }).catch((err) => {
        this.loading = false;
        this.$message.error(err);
      });
   }
  },
  components: {
    "my-secondTitle": secondTitle,
    "my-navder": Navdar
  },
};
</script>
<style scoped>
#controlElevator {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  width: 120px;
  margin: 0px 10px;
}
.modal1 {
  padding-bottom: 15px;
}
.floorTag{
  cursor:pointer;
  width:32px;
  height:32px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:50%;
}
.floorTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
.roomTag{
  cursor:pointer;
  min-width:42px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:5%;
}
.roomTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
</style>