<!--电梯模块--室内召梯-->
<template>
  <div id="indoorCallLadder">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%;border-right:1px solid rgba(12, 12, 12, 0.1); ">
        <my-navder :list="list" @select="getMsgFormSon"></my-navder>
      </a-spin>
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-headertitle>{{this.$t('elevatorIndoorErgencyRecall.a3')}}</my-headertitle>
          <div style="margin-top:20px;">
            <template v-for="tag in roomtags">
              <a-checkable-tag
                :key="tag.key"
                :checked="selectedRoomTags.indexOf(tag) > -1"
                @change="checked => handleChangeRoom(tag, checked)"
                style="cursor:pointer;"
                class="roomTag"
              >
                {{ tag.title }}
              </a-checkable-tag>
            </template>
            </div>
          </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="calendar" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{this.$t('elevatorIndoorErgencyRecall.a2')}}</span>
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleIndoorCallLadder" style="width:160px;">{{this.$t('elevatorIndoorErgencyRecall.a4')}}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import headerTitle from "../../../components/Title/headerTitle";
import Navdar from "../../../components/Navdar/vertical";
import { isEmptrValue,getApiFloorList,getApiRoomList,cmdControlRemoteFunction } from "../../../api/elevator";
export default {
  data() {
    return {
      name: "",
      spinning: false,
      list: [],
      roomtags: [],
      selectedRoomTags:[],
      id: undefined,
      loading: false,
    };
  },
  props: {    
    siteid: {
      type: Object,
    },
  },
  watch:{
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();
  },
  methods: {
   navderList(){
     const listtemp = [{ title: this.$t('elevatorIndoorErgencyRecall.a1'),key: '1-1',children:[]}]
     const params = {'fr_ID':this.siteid.build}
     getApiFloorList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = {key:item.id+'_'+item.fr_show,title:item.fr_show}
              listtemp[0].children.push(model)
              }
          )
          this.list =  listtemp
        }else{
          this.list =  listtemp 
        }
      }
     )
   },
   getMsgFormSon(data){
     this.id = parseInt(data.split('_')[0])
     this.selecteddesFloorTags = []
     this.roomtags = []
     this.selectedRoomTags = []
     this.getRoomLists(data.split('_')[1])     
   },
   getRoomLists(disPlayfloor){
     const params =  {'rm_bd_ID':this.siteid.build,'rm_floor':disPlayfloor}
     const listtemp = []
     getApiRoomList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = {key:item.rm_ID,title:item.rm_ShowName}
              listtemp.push(model)
              }
          )
          this.roomtags = listtemp 
        }else{
          this.roomtags = listtemp 
        }
      }
    )
   },
   handleChangeRoom(tag,checked){
     const { selectedRoomTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selectedRoomTags.filter(t => t !== tag);
      this.selectedRoomTags = nextSelectedTags;
   },
  handleIndoorCallLadder(){
     this.loading = true;
     const params = { "ele_ID": 0,"funcType": 2,"srcRoomId": this.selectedRoomTags[0].key,"desRoomId": 0}
     cmdControlRemoteFunction(params).then((res)=>{
       if(res.code===200){
         this.loading = false;
              this.$message.success(
                this.$t('elevatorIndoorErgencyRecall.a5')
              );
              setTimeout(() => {
                
              }, 5000);
       }else{
         this.loading = false;
         this.$message.error(this.$t('elevatorIndoorErgencyRecall.a6'));
           setTimeout(() => {
              
           }, 3000);
       }
     })
   },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-navder": Navdar
  },
};
</script>
<style scoped>
#indoorCallLadder {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  width: 120px;
  margin: 0px 10px;
}
.modal1 {
  padding-bottom: 15px;
}
.floorTag{
  cursor:pointer;
  width:32px;
  height:32px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:50%;
}
.floorTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
.roomTag{
  cursor:pointer;
  min-width:42px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:5%;
}
.roomTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
</style>