<!--电梯模块--远程开锁-->
<template>
  <div id="remoteOpenLock">
   <div class="aside" >
      <a-spin :spinning="spinning" style="width: 100%; height: 100%;border-right:1px solid rgba(12, 12, 12, 0.1); ">
        <my-navder :list="list"  @select="getMsgFormSon"></my-navder>
      </a-spin>
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-secondTitle>{{this.$t('elevatorRemoteemErgencyRecall.a3')}}</my-secondTitle>
          <div style="margin-top:16px;">
            <template v-for="tag in desfloortags">
              <a-checkable-tag
                :key="tag.key"
                :checked="selecteddesFloorTags.indexOf(tag) > -1"
                @change="checked => handleChangedesFloor(tag, checked)"
                style="margin-bottom:8px;cursor:pointer;"
                class="floorTag"
              >
                {{ tag.title }}
              </a-checkable-tag>
            </template>
          </div>
           <my-secondTitle>{{this.$t('elevatorRemoteemErgencyRecall.a4')}}</my-secondTitle>
           <div style="margin-top:16px;">
                <template v-for="tag in desroomtags">
                <a-checkable-tag
                    :key="tag.key"
                    :checked="selecteddesRoomTags.indexOf(tag) > -1"
                    @change="checked => handleChangedesRoom(tag, checked)"
                    style="cursor:pointer;"
                    class="roomTag"
                >
                    {{ tag.title }}
                </a-checkable-tag>
                </template>
            </div>
          </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="environment" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{this.$t('elevatorRemoteemErgencyRecall.a2')}}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button type="primary" @click="handleRemoteOpenLock" style="width:160px;">{{this.$t('elevatorRemoteemErgencyRecall.a5')}}</a-button>
        <a-button type="primary" @click="handleRemoteOpenLockAndCallElevator" :disabled="selecteddesRoomTags.length === 0" style="width:280px;">{{this.$t('elevatorRemoteemErgencyRecall.a6')}}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import secondTitle from "../Title/secondTitle";
import Navdar from "../../../components/Navdar/vertical";
import { isEmptrValue,getApiLadyList,getApiFloorList,getApiRoomList,cmdControlRemoteFunction } from "../../../api/elevator";
export default {
  data() {
    return {
      name: "",
      spinning: false,
      list: [],
      id:0,
      desfloortags: [],
      selecteddesFloorTags:[],
      desroomtags: [],
      selecteddesRoomTags:[],
      id: undefined,
      loading: false,    
    };
  },
  props: {    
   siteid: {
     type: Object,
   },
 },
  watch: {
  },
  
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();
  },
  methods: {
   navderList(){
      const listtemp = [ {title: this.$t('elevatorRemoteemErgencyRecall.a1'),key: '1',children:[]}]
      const params = {'ele_clientId':this.siteid.client,'ele_areaId':this.siteid.area,'ele_buildingID':this.siteid.build,"ele_reader_type": 2}
      getApiLadyList(params).then((res)=>{
          if(res.code===200){
            res.data.forEach(
              item =>{
                if(item.ele_reader_type===2){
                  const model = {key:item.ele_ID,title:item.ele_name}
                  listtemp[0].children.push(model) 
                }
                }
            )
            this.list =  listtemp
          }else{
            this.list =  listtemp 
          }
        }
      )
   },
   getMsgFormSon(data){
     this.id = data
     this.desfloortags = []
     this.selecteddesFloorTags = []
     this.desroomtags = []
     this.selecteddesRoomTags = []
     getApiFloorList({'fr_ID':this.siteid.build}).then((res)=>{
          if(res.code===200){
            res.data.forEach(
              item =>{
                const model = {key:item.id,title:item.fr_show}
                this.desfloortags.push(model)
                }
            )
          }else{
          }
        }
      )
   },
   handleChangeLadder(tag,checked){
     const { selectedLadderTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selectedLadderTags.filter(t => t !== tag);
      this.selectedLadderTags = nextSelectedTags;
   },
   handleChangedesFloor(tag,checked){
     const { selecteddesFloorTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selecteddesFloorTags.filter(t => t !== tag);
      this.selecteddesFloorTags = nextSelectedTags;
      if(nextSelectedTags.length>0){
        this.getDesRoomLists(nextSelectedTags[0].title)
      }
   },
   handleChangedesRoom(tag,checked){
     const { selecteddesRoomTags } = this;
      const nextSelectedTags = checked
        ? [tag]
        : selecteddesRoomTags.filter(t => t !== tag);
      this.selecteddesRoomTags = nextSelectedTags;
   },
   getDesRoomLists(disPlayfloor){
     const params =  {'rm_bd_ID':this.siteid.build,'rm_floor':disPlayfloor}
     const listtemp = []
     getApiRoomList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const model = {key:item.rm_ID,title:item.rm_ShowName}
              listtemp.push(model)
              }
          )
          this.desroomtags = listtemp 
        }else{
          this.desroomtags = listtemp 
        }
      }
    )
   },
   handleRemoteOpenLock(){
     this.loading = true;
     const params = { "ele_ID": this.id,"funcType": 3,"srcRoomId": 0,"desRoomId": 0}
     cmdControlRemoteFunction(params).then((res)=>{
       if(res.code===200){
          this.$message.success(
            this.$t('elevatorRemoteemErgencyRecall.a7')
          );
          setTimeout(() => {
            
          }, 3000);
       }else{
         this.$message.error(this.$t('elevatorRemoteemErgencyRecall.a8'));
           setTimeout(() => {
              
           }, 3000);
       }
       this.loading = false;
     })
   },
   handleRemoteOpenLockAndCallElevator(){
     this.loading = true;
     const params = { "ele_ID": this.id,"funcType": 3,"srcRoomId": 0,"desRoomId": this.selecteddesRoomTags[0].key}
     cmdControlRemoteFunction(params).then((res)=>{
       if(res.code===200){
         this.$message.success(this.$t('elevatorRemoteemErgencyRecall.a7'));
         setTimeout(() => {}, 3000);
       }else{
         this.$message.error(this.$t('elevatorRemoteemErgencyRecall.a8'));
         setTimeout(() => {   }, 3000);
       }
       this.loading = false;
     })
   }
  },
  components: {
    "my-secondTitle": secondTitle,
    "my-navder": Navdar
  },
};
</script>
<style scoped>
#remoteOpenLock {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  width: 120px;
  margin: 0px 10px;
}
.modal1 {
  padding-bottom: 15px;
}
.floorTag{
  cursor:pointer;
  width:32px;
  height:32px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:50%;
}
.floorTag:hover,.ladderTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
.ladderTag{
  cursor:pointer;
  min-width:42px;
  text-align:center;
  padding:0px 4px;
  line-height:32px;
  font-size:16px;
  border-radius:5%;
}
.roomTag{
  cursor:pointer;
  min-width:42px;
  text-align:center;
  padding:0;
  line-height:32px;
  font-size:16px;
  border-radius:5%;
}
.roomTag:hover{
  background:rgba(12, 12, 12, 0.1);
}
</style>