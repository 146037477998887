<!--页面标题组件-->
<template>
  <div id="secondTitle">
    <div class="secondTitle-left">
      <slot></slot>
    </div>
    <div class="secondTitle-right">
      <div class="secondTitle-right-name">
        <slot name="name"></slot>
      </div>
      <div v-show="quit">
        <a-icon
          type="close"
          @click="returnTo"
          :style="{ fontSize: '20px', color: '#7682CE' }"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    quit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    returnTo() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
#secondTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  white-space: nowrap;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.secondTitle-left {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.secondTitle-right {
  display: flex;
  align-items: center;
}
.secondTitle-right-name {
  display: flex;
  align-items: center;
  padding-right: 100px;
  font-size: 13px;
}
.secondTitle-left::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 24px;
  background-color: #7682ce;
  margin-right: 8px;
}
</style>