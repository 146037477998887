<!--电梯模块--远程控制页-->
<template>
  <div id="runningState">
    <div class="header">
      <my-headertitle>{{this.$t('elevatorecontrol.a1')}}</my-headertitle>
      <a-select style="width: 120px;border-bottom: 1px solid rgba(12, 12, 12, 0.1);padding: 9px 0;" v-model="Pageselection" :options="typeselect">
      </a-select>
    </div>
    <transition name="slide-fade">
    <div class="main" v-loading="loading" :element-loading-text="$t('elevatorequipment.a5')" ref="main">
        <my-controlelevator v-if="Pageselection == '4'" :siteid="id"></my-controlelevator>
        <my-indoorCallLadder v-if="Pageselection == '3'" :siteid="id"></my-indoorCallLadder>
        <my-remoteOpenLock v-if="Pageselection == '2'"  :siteid="id"></my-remoteOpenLock>
        <my-exchangevisits v-if="Pageselection == '1'" :siteid="id"></my-exchangevisits>
        <my-controlTimetable v-if="Pageselection == '5'" :siteid="id" :scroll.sync="scroll" @refresh="tableHeight"></my-controlTimetable>
    </div>
     </transition>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import controlElevator from "./components/controlElevator";
import exchangeVisits from "./components/exchangeVisits";
import indoorCallLadder from "./components/indoorCallLadder";
import remoteOpenLock from "./components/remoteOpenLock";
import controlTimetable from "./components/controlTimetable";
import { isEmptrValue,getbuildsiteId} from "../../api/elevator";
export default {
  name: "remoteControl",
  data() {
    return {
      form:{
        region: 0,
        region1: 0,
        value: ['0-0-0'],
      },
      loading: false,
      options: [],
      cardStatelist: [
        {"openStatus":0,"openId":369,"name":"lhz梯控控制器","id":102,"type":101,"floor":"1F/全体","iconNo":245},
        {"openStatus":"-","openId":270,"name":"大门1入门人脸终端","id":103,"type":207,"floor":"1F/全体","iconNo":245},
        {"openStatus":"-","openId":271,"name":"大门1出门人脸终端","id":104,"type":207,"floor":"1F/全体","iconNo":1},
        {"openStatus":0,"openId":372,"name":"DOOR2","id":103,"type":101,"floor":"2F/全体","iconNo":2},
        {"openStatus":"-","openId":373,"name":"DOOR2入门读卡器","id":105,"type":201,"floor":"2F/全体","iconNo":239},
        {"openStatus":"-","openId":374,"name":"DOOR2出门读卡器","id":106,"type":201,"floor":"2F/全体","iconNo":243},
        {"openStatus":0,"openId":375,"name":"DOOR3","id":104,"type":101,"floor":"3F/全体","iconNo":238},
        {"openStatus":"-","openId":376,"name":"DOOR3入门读卡器","id":107,"type":201,"floor":"3F/全体","iconNo":240},
        {"openStatus":"-","openId":377,"name":"DOOR3出门读卡器","id":108,"type":201,"floor":"3F/全体","iconNo":238},
        {"openStatus":0,"openId":378,"name":"DOOR4","id":105,"type":101,"floor":"4F/全体","iconNo":238},
        {"openStatus":"-","openId":379,"name":"DOOR4入门读卡器","id":109,"type":201,"floor":"4F/全体","iconNo":238},
        {"openStatus":"-","openId":380,"name":"DOOR4出门读卡器","id":110,"type":201,"floor":"4F/全体","iconNo":238}],
      typeselect: [{ 'value': '1','label': this.$t('elevatorecontrol.a2') }, { 'value': '2','label': this.$t('elevatorecontrol.a3') }, { 'value': '3','label':this.$t('elevatorecontrol.a4') }, { 'value': '4','label': this.$t('elevatorecontrol.a5')}, { 'value': '5','label': this.$t('elevatorecontrol.a7') }],
      Pageselection: '1',
      icon: [
        {
          name: "栋内互访",
          url: require("../../../public/images/iconEleNo/status/equipment01.png"),
        },
        {
          name: "远程开锁",
          url: require("../../../public/images/iconEleNo/status/equipment02.png"),
        },
        {
          name: "室内召梯",
          url: require("../../../public/images/iconEleNo/status/equipment03.png"),
        },
        {
          name: "远程授权",
          url: require("../../../public/images/iconEleNo/status/equipment04.png"),
        },
        {
          name: "解除权限",
          url: require("../../../public/images/iconEleNo/status/equipment05.png"),
        }
      ],
      id:{
        client:0,
        area:0,
        build:0
      },
      scroll:{ y:240},
    };
  },
  async created() {
    await getbuildsiteId(this.$route.query.id).then((res) =>{
      this.id.client = res.data.bd_clientId
      this.id.area = res.data.bd_areaID
      this.id.build = res.data.bd_ID
      this.Pageselection = '4'
    }).catch((err) => {
        console.log("访问站点转换出错", err);
    });
  },
  watch: {
    form: {
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-controlelevator": controlElevator,
    "my-exchangevisits": exchangeVisits,
    "my-remoteOpenLock": remoteOpenLock,
    "my-indoorCallLadder": indoorCallLadder,
    "my-controlTimetable": controlTimetable,
  },
  mounted(){
    // this.tableHeight();
    window.addEventListener("resize",this.tableHeight)
  },
  beforeDestroy(){
    window.addEventListener("resize",this.tableHeight)
  },
  methods: {
    // 表格高度计算
    tableHeight(){
      let height = this.$refs.main.clientHeight;
      let tableHeight = height - 275;
      this.scroll.y = tableHeight
    },
  }
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#runningState {
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  overflow: hidden;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.main {
  width: 100%;
  height: calc(100% - 63px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0px;
  overflow: auto;
}
.empty {
  width: 200px;
  height: 1px;
  margin: 0 auto;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
</style>